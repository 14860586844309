import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import { Box } from 'theme-ui'

/**
 * Shadow me to add your own content
 */

const styles = {
  image: url => ({
    width: `100vw`,
    height: `100vh`,
    position: `absolute`,
    zIndex: 0,
    top: 0,
    left: 0,
    backgroundImage: `url(${url})`,
    backgroundRepeat: `no-repeat`,
    backgroundPosition: `top center`,
    backgroundSize: `contain`
  })
}

export default () => {
  const data = useStaticQuery(minimalHeaderBgQuery)
  const { publicURL } = (data && data.file) || {}

  if (!publicURL) return ''

  return <Box sx={styles.image(publicURL)}></Box>
}

const minimalHeaderBgQuery = graphql`
  query MinimalHeaderBgQuery {
    file(absolutePath: { regex: "/background.(jpeg|jpg|gif|png)/" }) {
      publicURL
    }
  }
`
